import React from "react";
import styled from "styled-components";
import mainImg from "../../assets/Images/portfolio/aprillxstone/main.jpg";
import img1 from "../../assets/Images/portfolio/aprillxstone/1.jpg";
import img2 from "../../assets/Images/portfolio/aprillxstone/2.jpg";
import img3L from "../../assets/Images/portfolio/aprillxstone/3L.jpg";
import img3R from "../../assets/Images/portfolio/aprillxstone/3R.jpg";
import img4 from "../../assets/Images/portfolio/aprillxstone/4.jpg";
import img5 from "../../assets/Images/portfolio/aprillxstone/5.jpg";
import img6 from "../../assets/Images/portfolio/aprillxstone/6.jpg";
import img7 from "../../assets/Images/portfolio/aprillxstone/7.jpg";
import img8 from "../../assets/Images/portfolio/aprillxstone/8.jpg";
import img9 from "../../assets/Images/portfolio/aprillxstone/9.jpg";
import img10 from "../../assets/Images/portfolio/aprillxstone/10.jpg";
import img11 from "../../assets/Images/portfolio/aprillxstone/11.jpg";
import Footer from "../../components/Footer";
import useWindowDimensions from "../../components/UsewindowDimensions";
import FullBox from "../../components/templates/FullBox";
import DivideBox from "../../components/templates/DivideBox";
import LeftSoloBox from "../../components/templates/LeftSoloBox";
import RightSoloBox from "../../components/templates/RightSoloBox";
import ParallelBox from "../../components/templates/ParallelBox";

const Aprillxstone = () => {
  React.useEffect(() => {
    window.scroll(0,0)
  }, []);
  const { width } = useWindowDimensions();
  return (
    <Container>
      <MainImageBox width={width}>
        <img src={mainImg} alt="asd" />
      </MainImageBox>
      <MainDescBox>
        <div className="descBox">
          <div className="titleContainer">
            <p className="title">
              Gadget, mobile charging & sterilizing device
            </p>
            <p>Category / Mobile accessory</p>
            <p>Client / April x Stone</p>
            <p>Year / 2019</p>
          </div>

          <div className="descContainer">
            <p className="firstDesc">
              This is a charger with alarm function and sterilization for this
              covid circumstance, which is created by April x Stone, the mobile
              accessory company. The scenario is to deliver the mobile phone
              with the alarm after it is fully charged and sterilized. We are
              inspired by the scenario from the user experiences of the analog
              toaster.
            </p>
            <p>
              The arrangement of the body, buttons, and details reminds the
              toaster, while the analog images are aroused by display UI. So
              users can operate instinctively with this metaphor design. With
              tone on tone color, the strong contrast of tiny textured body and
              high glossy finishing button vitalizes and specializes this
              relaxed design. Furthermore, we add not only planned function but
              also a lighting system which makes the space cozier before going
              to bed. It lights up with an indirect method at the bottom of
              body, and makes tone on tone mood more luxuriant from the LED
              cover’s pastel color. As a finishing touch, The sensitive pop
              colors are also planned for the millennials who have a diversity
              of characteristic.
            </p>
          </div>
        </div>
      </MainDescBox>
      <FullBox>
          <img src={img1} alt="" />
      </FullBox>
      <FullBox>
          <img src={img2} alt="" />
      </FullBox>
      <DivideBox leftImgSrc={img3L} rightImgSrc={img3R} />
      <FullBox>
          <img src={img4} alt="" />
      </FullBox>
      <ParallelBox>
        <img src={img5} alt="" />
      </ParallelBox>
      <FullBox>
          <img src={img6} alt="" />
      </FullBox>
      <FullBox>
          <img src={img7} alt="" />
      </FullBox>
      <FullBox>
          <img src={img8} alt="" />
      </FullBox>
      <LeftSoloBox>
          <img src={img9} alt="" />
      </LeftSoloBox>
      <RightSoloBox>
          <img src={img10} alt="" />
      </RightSoloBox>
      <ParallelBox>
          <img src={img11} alt="" />
      </ParallelBox>
      <div className="footer">
        <Footer />
      </div>
    </Container>
  );
};

export default Aprillxstone;

const Container = styled.section`
  width: 100%;

  .footer {
    margin-top: 200px;
    padding: 0 10%;
    @media only screen and (max-width: 768px) {
      padding: 0 5%;
      margin-top: 100px;
    }
  }
`;
const MainImageBox = styled.div`
  width: ${(props) => props.width};
  height: 100vh;
  @media only screen and (max-width: 1000px) {
    position: relative;
    height: 700px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width + 60}px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media only screen and (max-width: 1000px) {
      transform: scale(1.0);
    }
    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(100, 100);
      height: ${(props) => props.width}px;
      height: ${(props) => props.width}px;
      object-fit: cover;
      margin: auto;
    }
  }
`;

const MainDescBox = styled.div`
  max-width: 1300px;
  padding: 0 10%;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    position: relative;
    background-color: #f3f0ec;
    top: -60px;
  }

  .titleContainer {
    width: 50%;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .descContainer {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .descBox {
    width: 100%;
    display: flex;
    padding: 90px 0;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  .firstDesc {
    margin-bottom: 20px;
  }
  div {
    margin: 0 auto;
  }
`;


