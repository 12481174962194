import React from 'react';
import styled from 'styled-components';

const DivideBox = ({leftImgSrc, rightImgSrc}) => {
  return (
    <Container>
      <ImgBox>
        <img id='firstImg' src={leftImgSrc} alt="leftImg" />
      </ImgBox>
      <ImgBox>
        <img src={rightImgSrc} alt="rightImg" />
      </ImgBox>
    </Container>
  );
}

export default DivideBox;
const Container = styled.div`
max-width: 1300px;
margin: 0 auto 50px auto;
padding: 0 10%;
display: flex;
gap: 50px;
height: 100%;

 @media only screen and (max-width: 768px) {
   padding: 0 5%;
   margin-bottom: 5%;
   flex-direction: column;
   gap: 0;
  }

  #firstImg {
    margin-bottom: 0;

    @media only screen and (max-width: 768px) {
      margin-bottom: 5%;
  }
  }

  img{
    width: 100%;
    /* height: 700px; */
    object-fit: cover;
    @media only screen and (max-width: 1000px) {
       /* height: 600px; */
     }
    @media only screen and (max-width: 768px) {
      /* height: 300px; */
      width: 100%;
    }
  }
`;

const ImgBox = styled.div`
width: 50%;

@media only screen and (max-width: 768px) {
width: 100%;
  }
`;

// const Box2 = styled.div`
// max-width: 1300px;
// margin: 0 auto 50px auto;
// padding: 0 10%;
// display: flex;
// column-gap: 50px;

//  @media only screen and (max-width: 768px) {
//    padding: 0 5%;
//    margin-bottom: 5%;
//    flex-direction: column;
//    row-gap: 50px;
//   }

//   img{
//     width: 100%;
//     height: 700px;
//     object-fit: cover;
//     @media only screen and (max-width: 1000px) {
//        height: 600px;
//      }
//     @media only screen and (max-width: 768px) {
//       height: 300px;
//       width: 100%;
//     }
//   }
// `;