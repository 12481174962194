import React, { useMemo } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import triEngle from "../../src/assets/Images/footer/tri-angle-footer.png";

const Footer = () => {
  const location = useLocation();
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const nowDate = useMemo(() => new Date().getFullYear(), []) 

  return (
    <>
      <Top location={location} onClick={scrollTop}>
        <img src={triEngle} alt="tri" />
      </Top>
      <Bot location={location}>
        <div className="left">ⓒ {nowDate} DIVE</div>
        <div className="right"> <a href="https://www.instagram.com/divedesign_studio/">Instagram</a></div>
      </Bot>
    </>
  );
};

export default Footer;

const Top = styled.div`
  margin-bottom: 10px;
  img {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const Bot = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 50px;
  font-size: 15px;
	@media only screen and (max-width: 768px) {
		font-size: 12px;
    margin-bottom: 22px;
  }
`;
