import React from "react";
import styled from "styled-components";
import mainImg from "../../assets/Images/portfolio/kistchhouse/main.jpg";
import img1 from "../../assets/Images/portfolio/kistchhouse/1.jpg";
import img2 from "../../assets/Images/portfolio/kistchhouse/2.jpg";
import img3 from "../../assets/Images/portfolio/kistchhouse/3.jpg";
import img4 from "../../assets/Images/portfolio/kistchhouse/4.jpg";
import img5 from "../../assets/Images/portfolio/kistchhouse/5.jpg";
import Footer from "../../components/Footer";
import useWindowDimensions from "../../components/UsewindowDimensions";
import FullBox from "../../components/templates/FullBox";

const Kistchhouse = () => {
  React.useEffect(() => {
    window.scroll(0,0)
  }, []);
  const { width } = useWindowDimensions();
  return (
    <Container>
      <MainImageBox width={width}>
        <img src={mainImg} alt="asd" />
      </MainImageBox>
      <MainDescBox>
        <div className="descBox">
          <div className="titleContainer">
            <p className="title">Kitsch house, Apartment public pet parking</p>
            <p>Category / Apartment public establishment</p>
            <p>Client / Rodem furniture</p>
            <p>Year / 2019</p>
          </div>
          <div className="descContainer">
            <p className="firstDesc">
              Kitsch house is a pet-parking facility cooperated with the Korean
              Rodem Furniture where provides public establishments. This house
              is placed in the apartment complex, especially ‘a no pet zone’, so
              that owners can leave their dogs safely.
            </p>
            <p>
              We create friendly and kitsch design language, reminiscent of
              child playground that reduces the sense of refusal for the owners
              and dogs. Furthermore, it provides safety and durability as it is
              produced with FRP material which is used as kids’ toys. The
              drawer, only available to be opened with a membership card, has a
              leash holder so it keeps the leash safely and holds quietly.
              Additionally, users can keep the poop bags and their belongings on
              the tray. LED is nstalled in the back of the drawer and lights up
              in the cage. This simple and effective ideas deliver considerate
              experiences for users and pets.
            </p>
          </div>
        </div>
      </MainDescBox>
      <FullBox>
        <img src={img1} alt="" />
      </FullBox>
      <FullBox>
        <img src={img2} alt="" />
      </FullBox>
      <FullBox>
        <img src={img3} alt="" />
      </FullBox>
      <FullBox>
        <img src={img4} alt="" />
      </FullBox>
      <FullBox>
        <img src={img5} alt="" />
      </FullBox>

      <div className="footer">
        <Footer />
      </div>
    </Container>
  );
};

export default Kistchhouse;

const Container = styled.section`
  width: 100%;

  .footer {
    margin-top: 200px;
    padding: 0 10%;
    @media only screen and (max-width: 768px) {
      padding: 0 5%;
      margin-top: 100px;
    }
  }
`;
const MainImageBox = styled.div`
  width: ${(props) => props.width};
  /* width: 100%; */
  height: 100vh;
  @media only screen and (max-width: 1000px) {
    position: relative;
    height: 700px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width + 60}px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media only screen and (max-width: 1000px) {
      transform: scale(1.0);
    }
    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(100, 100);
      height: ${(props) => props.width}px;
      height: ${(props) => props.width}px;
      object-fit: cover;
      margin: auto;
    }
  }
`;

const MainDescBox = styled.div`
  max-width: 1300px;
  padding: 0 10%;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    position: relative;
    background-color: #f3f0ec;
    top: -60px;
  }

  .titleContainer {
    width: 50%;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .descContainer {
    width: 50%;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .descBox {
    width: 100%;
    display: flex;
    padding: 90px 0;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  .firstDesc {
    margin-bottom: 20px;
  }
  div {
    margin: 0 auto;
  }
`;