const size = {
	mobile: '768px',
	tablet: '992px',
	pc: '1200px',
};

const theme = {
	mainColor: '#0a4297',
	mobile: `(max-width: ${size.mobile})`,
	tablet: `(max-width: ${size.tablet})`,
	pc: `(max-width: ${size.pc})`,
	mainBgColor: '#E5E5E5',
};

export default theme;
