import React from 'react';
import styled from 'styled-components';

const RightSoloBox = ({children}) => {
  return (
    <Container>
      <ImgSection>{children}</ImgSection>
    </Container>
  );
}

export default RightSoloBox;
const Container = styled.div`
max-width: 1300px;
margin: 0 auto;
padding: 0 10%;
margin-bottom: 50px;
display: flex;

 @media only screen and (max-width: 768px) {
   padding: 0 5%;
   margin-bottom: 5%;
 }
`;

const ImgSection = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;


img{
  width: 48%;

  @media only screen and (max-width: 768px) {
    width: 100%;
 }
}
`;

// import React from 'react';
// import styled from 'styled-components';

// const RightSoloBox = ({children}) => {
//   return (
//     <Container>
//       <LeftSeciton></LeftSeciton>
//       <RightSection>{children}</RightSection>
//     </Container>
//   );
// }

// export default RightSoloBox;
// const Container = styled.div`
// max-width: 1300px;
// margin: 0 auto;
// padding: 0 10%;
// margin-bottom: 50px;
// display: flex;

//  @media only screen and (max-width: 768px) {
//    padding: 0 5%;
//    margin-bottom: 5%;
//  }
// `;

// const LeftSeciton = styled.div`
//   width: 52%;
//   @media only screen and (max-width: 1000px) {
//     width: 0;
//     }
//     @media only screen and (max-width: 768px) {
//       width: 0;
//     }

// `;

// const RightSection = styled.div`
//   width: 48%;

//   @media only screen and (max-width: 1000px) {
//     width: 100%;
//     }
//     @media only screen and (max-width: 768px) {
//       width: 100%;
//     }
//   img {
//     width: 100%;
//     height: 1000px;
//     object-fit: cover;

//     /* @media only screen and (max-width: 1000px) {
//       height: 900px;
//     } */
//     @media only screen and (max-width: 768px) {
//       height: 800px;
//       width: 100%;
//     }
//   }
// `;