import React, { useRef } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import Header from './components/Header';
import Contact from './pages/Contact';
import Aprillxstone from './pages/Detail/Aprillxstone';
import Kepco from './pages/Detail/Kepco';
import Kistchhouse from './pages/Detail/Kistchhouse';
import KokomDetail from './pages/Detail/KokomDetail';
import Mintpot from './pages/Detail/MintPot';
import Main from './pages/Main';
import Office from './pages/Office';
import PreScreen from './pages/PreScreen';
import Projects from './pages/Projects';

const routes = [
	{ path: '/', name: 'PreScreen', Component: PreScreen },
	{ path: '/main', name: 'Main', Component: Main },
	{ path: '/office', name: 'Office', Component: Office },
	{ path: '/contact', name: 'Contact', Component: Contact },
	{ path: '/projects', name: 'Projects', Component: Projects },
	{ path: '/projects/kokom', name: 'KokomDetail', Component: KokomDetail },
	{ path: '/projects/minipot', name: 'MintPot', Component: Mintpot },
	{ path: '/projects/kepco', name: 'Kepco', Component: Kepco },
	{ path: '/projects/kistchhouse', name: 'Kistchhouse', Component: Kistchhouse },
	{ path: '/projects/aprillxstone', name: 'Aprillxstone', Component: Aprillxstone },
];

export default function Routes() {
	const nodeRef = useRef(null);

	return (
		<Router>
			<Header />
			<Box className="container">
				{routes.map(({ path, Component }) => (
					<Route key={path} exact path={path}>
						{({ match }) => (
							<CSSTransition in={match != null} timeout={1000} classNames="page" unmountOnExit>
								<div ref={nodeRef} className="page">
									<Component />
								</div>
							</CSSTransition>
						)}
					</Route>
				))}
			</Box>
		</Router>
	);
}

const Box = styled.div`
	.container {
		position: relative;
	}

	.page {
		position: absolute;
		left: 0;
		right: 0;
	}

	.page-enter {
		opacity: 0;
		/* transform: scale(1.1); */
	}

	.page-enter-active {
		opacity: 1;
		/* transform: scale(1); */
		transition: opacity 700ms, transform 400ms;
	}

	.page-exit {
		opacity: 1;
		/* transform: scale(1); */
	}

	.page-exit-active {
		opacity: 0;
		/* transform: scale(0.9); */
		transition: opacity 700ms, transform 400ms;
	}
`;
