import React from 'react';
import styled from 'styled-components';
import mainImg from '../../assets/Images/portfolio/nobletree/main.jpg';
import img1 from '../../assets/Images/portfolio/nobletree/1.jpg';
import img2 from '../../assets/Images/portfolio/nobletree/2.jpg';
import img3 from '../../assets/Images/portfolio/nobletree/3.jpg';
import img4 from '../../assets/Images/portfolio/nobletree/4.jpg';
import img5L from '../../assets/Images/portfolio/nobletree/5L.jpg';
import img5R from '../../assets/Images/portfolio/nobletree/5R.jpg';
import img6 from '../../assets/Images/portfolio/nobletree/6.jpg';
import img7 from '../../assets/Images/portfolio/nobletree/7.jpg';
import img8 from '../../assets/Images/portfolio/nobletree/8.jpg';
import img9 from '../../assets/Images/portfolio/nobletree/9.jpg';
import img10 from '../../assets/Images/portfolio/nobletree/10.jpg';
import img11 from '../../assets/Images/portfolio/nobletree/11.jpg';
import img12 from '../../assets/Images/portfolio/nobletree/12.jpg';
import Footer from '../../components/Footer';
import useWindowDimensions from '../../components/UsewindowDimensions';
import FullBox from '../../components/templates/FullBox';
import DivideBox from '../../components/templates/DivideBox';
import ParallelBox from '../../components/templates/ParallelBox';
import LeftSoloBox from '../../components/templates/LeftSoloBox';
import RightSoloBox from '../../components/templates/RightSoloBox';

const Mintpot = () => {
	React.useEffect(() => {
    window.scroll(0,0)
  }, []);
  const { width } = useWindowDimensions();
  return (
<Container>
			<MainImageBox width={width}>
				<img src={mainImg} alt="asd" />
			</MainImageBox>
			<MainDescBox>
				<div className="descBox">
					<div className="titleContainer">
						<p className="title">Eco-friendly plastic plant pot</p>
						<p>Category / Home living object</p>
						<p>Client / Geegu</p>
						<p>Year / 2020</p>
					</div>
					<div className="descContainer">
						<p className="firstDesc">
            This pot is the design for the Geegu which is the startup eco-friendly living brand. It is a small plant pot made of eco-friendly plastic so that users can put it on the furniture as an interior object. The bark is used, which is to keep moisturized, so that users can easily get the bark moisturized in the sink and put the plant in the door without the water stand.
						</p>
						<p>
            This is a very simple object but we create a lidded structure reminiscent of cosmetics cases that make its own unique attention. And the asymmetrical feature of the lid quietly emphasizes the iconic presence. This detailed feature helps the user to get water in the sink easily and makes the plant gracefully like it is wrapped as a bunch of flowers. Eco-friendly plastic is used which is a warm image of pottery materials. Moreover, three options of greeny colors, expressed the eco-friendly image makes you decorate it as the interior without any difficulties.
						</p>
					</div>
				</div>
			</MainDescBox>
			<FullBox>
				<img src={img1} alt="" />
			</FullBox>
			<FullBox>
				<img src={img2} alt="" />
			</FullBox>
			<FullBox>
				<img src={img3} alt="" />
			</FullBox>
			<FullBox>
				<img src={img4} alt="" />
			</FullBox>
			<DivideBox leftImgSrc={img5L} rightImgSrc={img5R} />
			<FullBox>
				<img src={img6} alt="" />
			</FullBox>
			<ParallelBox>
			<img src={img7} alt="" />
			</ParallelBox>

			<LeftSoloBox>
			<img src={img8} alt="" />
			</LeftSoloBox>
			<RightSoloBox>
			<img src={img9} alt="" />
			</RightSoloBox>

			<FullBox>
				<img src={img10} alt="" />
			</FullBox>

			<FullBox>
				<img src={img11} alt="" />
			</FullBox>

			<FullBox>
				<img src={img12} alt="" />
			</FullBox>
			<div className="footer">
				<Footer />
			</div>
		</Container>
  );
}

export default Mintpot;

const Container = styled.section`
	width: 100%;

  .footer {
    margin-top: 200px;
    padding: 0 10%;
    @media only screen and (max-width: 768px) {
      padding: 0 5%;
      margin-top: 100px;
    }
	}
`;
const MainImageBox = styled.div`
	width: ${(props) => props.width};
	height: 100vh;
	@media only screen and (max-width: 1000px) {
		position: relative;
		height: 700px;
	}
	@media only screen and (max-width: 768px) {
		position: relative;
		width: ${(props) => props.width}px;
		height: ${(props) => props.width + 60}px;
		overflow: hidden;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		@media only screen and (max-width: 1000px) {
			transform: scale(1.3);
		}
		@media only screen and (max-width: 768px) {
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(100, 100);
			height: ${(props) => props.width}px;
			height: ${(props) => props.width}px;
			object-fit: cover;
			margin: auto;
		}
	}
`;

const MainDescBox = styled.div`
	max-width: 1300px;
	padding: 0 10%;
	margin: 0 auto;

	@media only screen and (max-width: 1000px) {
		position: relative;
		background-color: #f3f0ec;
		top: -60px;
	}

	.titleContainer {
		width: 50%;
		font-size: 16px;
    line-height: 1.3;
		@media only screen and (max-width: 768px) {
			width: 100%;
			margin-bottom: 30px;
		}
	}

	.descContainer {
		width: 50%;
		font-size: 16px;
    line-height: 1.3;
		@media only screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.descBox {
		width: 100%;
		display: flex;
		padding: 90px 0;
		@media only screen and (max-width: 768px) {
			flex-direction: column;
		}
	}
	.title {
    font-size: 16px;
		font-weight: bold;
		margin-bottom: 22px;
	}

	.firstDesc {
		margin-bottom: 20px;
	}
	div {
		margin: 0 auto;
	}
`;


