import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import angle from "../assets/Images/header/black-tri-angle.png";
import logo from "../assets/Images/header/logo.png";
import Modal from "./Modal";
import useWindowDimensions from "./UsewindowDimensions";

export default function Header() {
  const location = useLocation();
  const { width } = useWindowDimensions();

  const [show, setShow] = useState(true);
  const [defaultDot, setDefaultDot] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [inDot, setInDot] = useState({
    office: false,
    contact: false,
    logo: false,
    projects: false,
    home: false,
  });
  //
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [inProp, setInProp] = useState(false);

  function noScroll() {
    window.scrollTo(0, 0);
  }

  function disableScroll() {
    document.body.style.overflow = 'hidden';
    document.querySelector('html').scrollTop = window.scrollY;
    }
    
    function enableScroll() {
    document.body.style.overflow = null;
    }
  

  useEffect(() => {
    function handleTouchMove(event) {
      if (isOpenMenu) {
        event.preventDefault();
      }
    }
    if(isOpenMenu) {
      window.addEventListener("touchmove", handleTouchMove, {
        passive: false
      });
      disableScroll()
      window.addEventListener('scroll', noScroll);
    }
    return () =>{
      window.removeEventListener('scroll', noScroll);
      window.removeEventListener("touchmove", handleTouchMove);
      enableScroll()
    }
      
  }, [isOpenMenu]);

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      setShow(false);
    } else {
      setShow(true);
      if (location.pathname === "/main") {
        setDefaultDot("main");
        setInDot({ logo: true });
      } else if (location.pathname === "/office") {
        setDefaultDot("office");
        setInDot({ office: true });
      } else if (location.pathname === "/contact") {
        setDefaultDot("contact");
        setInDot({ contact: true });
      } else if (location.pathname === "/projects") {
        setDefaultDot("projects");
        setInDot({ projects: true });
      } else if (location.pathname === "/") {
        setDefaultDot("home");
        setInDot({ projects: true });
      }
    }
  }, [location.pathname]);

  const inHover = (e) => {
    const name = e.target.innerText;
    const altName = e.target.alt;
    if (name === "Office") {
      setInDot({ office: true });
    } else if (name === "Contact") {
      setInDot({ contact: true });
    } else if (altName === "logo") {
      setInDot({ logo: true });
    } else if (name === "Projects") {
      setInDot({ projects: true });
    } else if (name === "Home") {
      setInDot({ home: true });
    }
  };

  const offHover = (e) => {
    const name = e.target.innerText;
    const altName = e.target.alt;
    if (name === "Office") {
      setInDot({ office: false });
      setDefault();
    } else if (name === "Contact") {
      setInDot({ contact: false });
      setDefault();
    } else if (altName === "logo") {
      setInDot({ logo: false });
      setDefault();
    } else if (name === "Projects") {
      setInDot({ projects: false });
      setDefault();
    } else if (name === "Home") {
      setInDot({ home: false });
      setDefault();
    }
  };

  const setDefault = () => {
    if (defaultDot === "office") {
      setInDot({ office: true });
    } else if (defaultDot === "contact") {
      setInDot({ contact: true });
    } else if (defaultDot === "main") {
      setInDot({ logo: true });
    } else if (defaultDot === "projects") {
      setInDot({ projects: true });
    }
  };

  const onOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
    setInProp(!inProp);
  };

  return (
    <>
      {show ? (
        <>
          <Box
            location={location.pathname}
            style={scrollPosition < 100 ? { opacity: 1 } : { opacity: 0 }}
          >
            <NavLeft>
              <ItemBox style={{ marginRight: "10px" }}>
                <Link to={"/office"}>
                  <Item onMouseOver={inHover} onMouseOutCapture={offHover}>
                    Office
                  </Item>
                </Link>
                <div>
                  <Angle
                    className="office"
                    inDot={inDot.office}
                    src={angle}
                    alt="angle"
                  />
                </div>
              </ItemBox>
              <ItemBox>
                <Link to={"/contact"}>
                  <Item onMouseOver={inHover} onMouseOutCapture={offHover}>
                    Contact
                  </Item>
                </Link>
                <div>
                  <Angle
                    className="contact"
                    inDot={inDot.contact}
                    src={angle}
                    alt="angle"
                  />
                </div>
              </ItemBox>
            </NavLeft>
            <NavCenter>
              {location.pathname === "/office" && width > 768 ? null : (
                <Link to={"/main"}>
                  <Logo
                    onMouseOver={inHover}
                    onMouseOutCapture={offHover}
                    src={logo}
                    alt="logo"
                  />
                  <MainAngle
                    className="logo"
                    inDot={inDot.logo}
                    src={angle}
                    alt="angle"
                  />
                </Link>
              )}

              <MobileNavBtn onClick={() => onOpenMenu()}>
                <MobileNavAngle
                  isOpenMenu={isOpenMenu}
                  src={angle}
                  alt="angle"
                />
              </MobileNavBtn>
            </NavCenter>
            <NavRight>
              {location.pathname !== "/office" && width > 768 ? null : (
                <ItemBox style={{ marginRight: "10px" }}>
                  <Link to={"/main"}>
                    <Item onMouseOver={inHover} onMouseOutCapture={offHover}>
                      Home
                    </Item>
                  </Link>
                  <div>
                    <Angle
                      className="office"
                      inDot={inDot.home}
                      src={angle}
                      alt="angle"
                    />
                  </div>
                </ItemBox>
              )}

              <ItemBox>
                <Link to={"/projects"}>
                  <Item onMouseOver={inHover} onMouseOutCapture={offHover}>
                    Projects
                  </Item>
                </Link>
                <div>
                  <Angle
                    className="projects"
                    inDot={inDot.projects}
                    src={angle}
                    alt="angle"
                  />
                </div>
              </ItemBox>
            </NavRight>
          </Box>
          <Transition unmountOnExit in={isOpenMenu} timeout={400}>
            {(state) => (
              <div
                onClick={() => {
                  onOpenMenu();
                }}
              >
                <Modal state={state}></Modal>
              </div>
            )}
          </Transition>
        </>
      ) : null}
    </>
  );
}

const Box = styled.section`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  top: 0;
  z-index: 1;
  transition: ease-in-out 500ms;
  margin-top: 30px;

  @media only screen and (max-width: 768px) {
    position: absolute;
    width: 100%;
    height: 29px;
  }
`;

const NavCenter = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    align-items: flex-end;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
`;

const NavLeft = styled.div`
  width: 150px;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-left: 5%;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NavRight = styled.div`
  margin-right: 4.5%;
  width: 150px;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ItemBox = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Logo = styled.img`
  max-width: 100px;
  @media only screen and (max-width: 768px) {
    height: 20px;
    margin-top: 10px;
    object-fit: contain;
    background-color: transparent;
  }
`;

const MainAngle = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 4px;
  opacity: ${(props) => (props.inDot ? 1 : 0)};
  transition: ease-in-out 500ms;

  @media only screen and (max-width: 768px) {
    width: 8px;
    height: 8px;
    display: none;
  }
`;

const Angle = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 2px;
  opacity: ${(props) => (props.inDot ? 1 : 0)};
  transition: ease-in-out 500ms;
`;

const MobileNavBtn = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileNavAngle = styled.img`
  width: 20px;
  transform: ${(props) =>
    props.isOpenMenu ? "rotate(45deg)" : "rotate(0deg)"};
  transition: ease-in-out 400ms;
  position: relative;
  margin-top: ${(props) => (props.isOpenMenu ? "0px" : "11px")};
`;
