import React from "react";
import styled from "styled-components";
import mainImg from "../../assets/Images/portfolio/kocom/main.jpg";
import img1 from "../../assets/Images/portfolio/kocom/1.jpg";
import img2 from "../../assets/Images/portfolio/kocom/2.jpg";
import img3 from "../../assets/Images/portfolio/kocom/3.jpg";
import img4 from "../../assets/Images/portfolio/kocom/4.jpg";
import img5 from "../../assets/Images/portfolio/kocom/5.jpg";
import img6 from "../../assets/Images/portfolio/kocom/6.jpg";
import Footer from "../../components/Footer";
import useWindowDimensions from "../../components/UsewindowDimensions";
import FullBox from "../../components/templates/FullBox";
import DivideBox from "../../components/templates/DivideBox";

export default function KokomDetail() {
  React.useEffect(() => {
    window.scroll(0,0)
  }, []);
  const { width } = useWindowDimensions();
  return (
    <Container>
      <MainImageBox width={width}>
        <img src={mainImg} alt="asd" />
      </MainImageBox>
      <MainDescBox>
        <div className="descBox">
          <div className="titleContainer">
            <p className="title">The frame, Premium control wall pad</p>
            <p>Category / Smart-home appliance</p>
            <p>Client / Kocom</p>
            <p>Year / 2021</p>
          </div>
          <div className="descContainer">
            <p className="firstDesc">
              The Frame is the design for developing the premium line up of
              Kocom which is the typical Korean smart home appliance company.
              The home IOT equipment and front door can be controlled by this
              smart wall-pad which is installed in the premium apartments with
              the size of 13.3 inches and the full screen touch system.
            </p>
            <p>
              The design of the frame shows its unique presence with the minimal
              and luxurious color and furnished details, while avoiding the
              normal sea of white boxes in the market. The magic mirror display
              with the frame from the details of high-end furniture makes it as
              the melted into premium interior. A quiet color with the
              charismatic feature and soft fabric of Kvadrat complete the
              well-balanced design.
            </p>
          </div>
        </div>
      </MainDescBox>
      <FullBox>
        <img src={img1} alt="" />
      </FullBox>
      <FullBox>
        <img src={img2} alt="" />
      </FullBox>
      <DivideBox leftImgSrc={img3} rightImgSrc={img4} />
      <FullBox>
        <img src={img5} alt="" />
      </FullBox>
      <FullBox>
        <img src={img6} alt="" />
      </FullBox>
      <div className="footer">
        <Footer />
      </div>
    </Container>
  );
}

const Container = styled.section`
  width: 100%;

  .footer {
    margin-top: 200px;
    padding: 0 10%;
    @media only screen and (max-width: 768px) {
      padding: 0 5%;
      margin-top: 100px;
    }
  }
`;

const MainImageBox = styled.div`
  width: ${(props) => props.width};
  height: 100vh;
  @media only screen and (max-width: 1000px) {
    position: relative;
    height: 700px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width + 60}px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media only screen and (max-width: 1000px) {
      transform: scale(1.0);
    }
    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(100, 100);
      height: ${(props) => props.width}px;
      height: ${(props) => props.width}px;
      object-fit: cover;
      margin: auto;
    }
  }
`;

const MainDescBox = styled.div`
  max-width: 1300px;
  padding: 0 10%;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    position: relative;
    background-color: #f3f0ec;
    top: -60px;
  }

  .titleContainer {
    width: 50%;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .descContainer {
    width: 50%;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .descBox {
    width: 100%;
    display: flex;
    padding: 90px 0;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  .firstDesc {
    margin-bottom: 20px;
  }
  div {
    margin: 0 auto;
  }
`;

