import React from 'react';
import styled from 'styled-components';

const FullBox = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default FullBox;
const Container = styled.div`
max-width: 1300px;
margin: 0 auto;
padding: 0 10%;
margin-bottom: 50px;

 @media only screen and (max-width: 768px) {
   padding: 0 5%;
   margin-bottom: 5%;
  }

  img {
    width: 100%;
    object-fit: cover;

    @media only screen and (max-width: 1000px) {
    }
    @media only screen and (max-width: 768px) {
      /* height: 300px; */
      width: 100%;
    }
  }`;


// legacy
// const FullBox = styled.div`
//   max-width: 1300px;
//   padding: 0 10%;
//   margin: 0 auto;
//   margin-bottom: 50px;
//   @media only screen and (max-width: 768px) {
//     padding: 0 5%;
//     margin-bottom: 5%;
//   }
  // img {
  //   width: 100%;
  //   height: 700px;
  //   object-fit: cover;

  //   @media only screen and (max-width: 1000px) {
  //     height: 600px;
  //   }
  //   @media only screen and (max-width: 768px) {
  //     height: 300px;
  //     width: 100%;
  //   }
  // }
// `;