/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';
import useWindowDimensions from '../components/UsewindowDimensions';

export default function Main() {
	const [images, setImages] = useState([]);
	const [count, setCount] = useState(0);
	const [state, setState] = React.useState(true);
	const { height, width } = useWindowDimensions();

	useEffect(() => {
		const mainImages = ['kepco', 'kistchhouse', 'kocom', 'nobletree', 'aprillxstone'];
		const imagesPreload = mainImages.map((name) => name === 'kepco' && width <800 ? require(`../assets/Images/mobile/KepcoM.jpg`) : name === "nobletree"&& width <800 ? require(`../assets/Images/mobile/minipPotM.jpg`) : name === "aprillxstone"&& width <800 ?require(`../assets/Images/mobile/AprilM.jpg`)  : require(`../assets/Images/portfolio/${name}/main.jpg`));
		setImages(imagesPreload);

		const contactImageArr = ['contact-2', 'contact-1', 'contact-2-m']
		const contactImagesPreload = contactImageArr.map((name) => require(`../assets/Images/contact/${name}.jpg`));
		const portFolioImagesPreload = [require('../assets/Images/mobile/AprilFullM.jpg'),require("../assets/Images/portfolio/nobletree/6.jpg"),require('../assets/Images/portfolio/aprillxstone/6.jpg'),require("../assets/Images/portfolio/kepco/2.jpg"),require("../assets/Images/mobile/Kocom.jpg"),require("../assets/Images/portfolio/nobletree/1.jpg")]

		const imagesPreloadArray = [...contactImagesPreload, ...portFolioImagesPreload]
		imagesPreloadArray.map((image) => {
			const newImage = new Image();
			newImage.src = image.default;

			return (window[image] = newImage);
		});

	}, []);

	useEffect(() => {
		setTimeout(() => {
			setState((state) => !state);
			if (count === 4) {
				setCount(0);
			} else {
				setCount(count + 1);
			}
		}, 5000);
	}, [count]);

	return (
		<Box>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={state}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames="fade"
				>
					<MainImages width={width} height={height}>
						{images.length !==0 && <img className={count} src={`${images[count]}`} alt="main" />}
					</MainImages>
				</CSSTransition>
			</SwitchTransition>
			<SnsLink>
				<span>ⓒ 2021 DIVE</span> <span>INSTAGRAM</span>
			</SnsLink>
		</Box>
	);
}

const Box = styled.section`
overflow: hidden;

	.fade-enter {
		opacity: 0;
	}
	.fade-exit {
		opacity: 1;
	}
	.fade-enter-active {
		opacity: 1;
	}
	.fade-exit-active {
		opacity: 0;
	}

	.fade-enter-active {
		transition: opacity 1300ms ease-in;
	}

	.fade-exit-active {
		transition: opacity 1000ms ease-out;
	}
`;

const MainImages = styled.div`
	position: relative;
	height: ${(props) => props.height}px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const SnsLink = styled.div`
	height: 93px;
	display: none;
	justify-content: space-between;
	align-items: center;
	margin: 0 93px;
	color: grey;
`;
