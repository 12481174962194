import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'Roboto', 'Noto Sans KR' !important;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  html, body {
    box-sizing: border-box;
    background-color: #F3F0EC;
    width: 100%; height: 100%;
    font-size: 10px;

    & > ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}
  }

  a:link { color: black; text-decoration: none;}

  a:visited { color: black; text-decoration: none;}

  a:hover { color: black; text-decoration: none;}


`;

export default GlobalStyle;
