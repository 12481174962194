import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import ReactTypingEffect from 'react-typing-effect';
import styled from 'styled-components';
import useWindowDimensions from '../components/UsewindowDimensions';

export default function PreScreen() {
	const history = useHistory();
	const { width, height } = useWindowDimensions();
	const goMain = () => {
		history.push('/main');
	};

	useEffect(() => {
		// const portfolioImageArr = ['kepco', 'kistchhouse', 'kocom', 'nobletree', 'aprillxstone'];
		// const officeImageArr = ['Mid3', 'Bot1', 'Bot2', 'Top1', 'Top2', 'Mid1']
		// const officeImagesPreload = officeImageArr.map((name) => require(`../assets/Images/office/${name}.jpg`));
		// const portfolioImagesPreload = portfolioImageArr.map((name) => require(`../assets/Images/portfolio/${name}/main.jpg`));
		const portfolioImageArr = ['kepco', 'kistchhouse', 'kocom', 'nobletree', 'aprillxstone'];
		const portfolioImagesPreload = portfolioImageArr.map((name) => require(`../assets/Images/portfolio/${name}/main.jpg`));

		const imagesPreloadArray = [...portfolioImagesPreload]
		imagesPreloadArray.map((image) => {
			const newImage = new Image();
			newImage.src = image.default;

			return (window[image] = newImage);
		});
		// const imagesPreloadArray = [...portfolioImagesPreload, ...officeImagesPreload]
		// imagesPreloadArray.map((image) => {
		// 	const newImage = new Image();
		// 	newImage.src = image.default;

		// 	return (window[image] = newImage);
		// });
		

		setTimeout(() => {
			goMain();
		}, 6000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box width={width} height={height}>
			<ReactTypingEffect
				text={['Hello,Welcome to DIVE studio.']}
				speed="150"
				eraseDelay="1000000"
				typingDelay="120"
				cursor="|"
				cursorRenderer={(cursor) => <span style={{ fontSize: width > 900 ? '70px' : '25px', textAlign: 'left' }}>{cursor}</span>}
				displayTextRenderer={(text) => {
					return (
						<div style={{ textAlign: 'center' }}>
							<h1>
								{text.split('').map((char, idx) => {
									const key = `${idx}`;
									if (char === 'W') {
										return (
											<>
												<br />
												<span key={key} style={{ fontSize: width > 900 ? '70px' : '25px' }}>
													{char}
												</span>
											</>
										);
									} else {
										return (
											<span key={key} style={{ fontSize: width > 900 ? '70px' : '25px' }}>
												{char}
											</span>
										);
									}
								})}
							</h1>
						</div>
					);
				}}
			/>
		</Box>
	);
}

const Box = styled.section`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
