import React from 'react';
import styled from 'styled-components';

const ParallelBox = ({children}) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

export default ParallelBox;
const Container = styled.div`
  max-width: 1300px;
  padding: 0 10%;
  margin: 0 auto;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    padding: 0 5%;
    margin-bottom: 5%;
  }
  img {
    width: 100%;

    @media only screen and (max-width: 1000px) {
      height: auto;
      object-fit: fill;
    }
    @media only screen and (max-width: 768px) {
    }
  }
`;