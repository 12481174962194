import React, { useEffect, useState } from "react";
import styled from "styled-components";
import mainImage from "../assets/Images/contact/contact-2.jpg";
import Footer from "../components/Footer";
import image from "../assets/Images/contact/contact-1.jpg";
import image2 from "../assets/Images/contact/contact-2-m.jpg";

const Contact = () => {
  const [windowWidth, setWindowWidth] = useState();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <Top>
        <ImageContainer>
          {windowWidth > 900 ?(<DeskImage src={mainImage} alt="main" />) : <MobileImage src={image2} alt="main" />}
        </ImageContainer>
        {windowWidth > 1000 && (
          <InfoContainer>
            <InfoItem>
              <InfoItemTitle>Email</InfoItemTitle>
              <InfoItemDesc>hello@divedesign.co</InfoItemDesc>
            </InfoItem>
            <InfoItemBar />
            <InfoItem>
              <InfoItemTitle>Call</InfoItemTitle>
              <InfoItemDesc>+82 10 3603 5004 </InfoItemDesc>
              <InfoItemDesc>+82 10 2616 5687 </InfoItemDesc>
            </InfoItem>
            <InfoItemBar />
            <InfoItem>
              <InfoItemTitle>Address</InfoItemTitle>
              <InfoItemDesc>
                3F, 55, Sangdo-ro 15-gil, Dongjak-gu, Seoul, Korea, 06950
              </InfoItemDesc>
              <InfoItemDesc>
                서울시 동작구 상도로15길 55, 3층
              </InfoItemDesc>
            </InfoItem>
            <InfoItemBar />
            <InfoItem>
              <InfoItemTitle>SNS</InfoItemTitle>
                <InfoItemDesc>Instagram / <a href="https://www.instagram.com/divedesign_studio/">@divedesign_studio</a></InfoItemDesc>
                <InfoItemDesc>Behance / <a href="https://www.behance.net/divedesign_studio">@dive_design</a></InfoItemDesc>
            </InfoItem>
          </InfoContainer>
        )}
        {windowWidth < 1000 && (
          <InfoContainer>
            <InfoItem>
              <InfoItemTitle>Email</InfoItemTitle>
              <div className="box">
                <InfoItemDesc>hello@divedesign.co</InfoItemDesc>
              </div>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>Call</InfoItemTitle>
              <div className="box">
                <InfoItemDesc>+82 10 3603 5004</InfoItemDesc>
                <InfoItemDesc>+82 10 2616 5687</InfoItemDesc>
              </div>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>Address</InfoItemTitle>
              <div className="box" style={{ lineHeight: 1.3 }}>
                <InfoItemDesc>2F, 17-1, Dongnam-ro 4-gil, </InfoItemDesc>
                <InfoItemDesc style={{ marginBottom: "3px" }}>
                  Songpa-gu, Seoul, Korea, 05804
                </InfoItemDesc>
                <InfoItemDesc>
                  서울시 송파구 동남로4길 17-1, 2층
                </InfoItemDesc>
              </div>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>SNS</InfoItemTitle>
              <div className="box">
                <InfoItemDesc>Instagram / @divedesign_studio</InfoItemDesc>
                <InfoItemDesc>Behance / @dive_design</InfoItemDesc>
              </div>
            </InfoItem>
          </InfoContainer>
        )}
      </Top>
      <Bottom>
        <DescriptionContainer>
          <p className="title">Join our team.</p>
          <p className="firstDesc">
            Dive is open to anyone who is enjoyable with the design. We recruit
            product/UX designers and engineers who want to develop potential
            with an enjoyable and informal working atmosphere from DIVE. Please
            join hello@devedesign.co with your CV and portfolio.
          </p>
          <p className="secondDesc">
            DIVE의 문은 디자인을 즐기는 누구에게나 열려있습니다. 자유로운 업무
            환경 속에서 자신만의 잠재력을 키우고,다이브와 함께 성장해나갈 제품,
            UX 디자이너와 엔지니어를 모집합니다. 간단한 이력서와 포트폴리오를
            첨부하여 hello@divedesign.co로 제출해주세요.
          </p>
        </DescriptionContainer>
        <DescriptionImageContainer>
          <img className="image" src={image} alt="contactsImage" />
        </DescriptionImageContainer>
      </Bottom>
      <Footer />
    </Container>
  );
};

export default Contact;

const Container = styled.section`
  margin: 0 auto;
  max-width: 1800px;
  padding: 150px 5% 0 5%;
  @media only screen and (max-width: 1200px) {
    padding-top: 120px;
  }
  @media only screen and (max-width: 768px) {
    padding: 100px 26px 0 26px;
  }
`;

const Top = styled.section`
  width: 100%;
  margin-bottom: 500px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin-bottom: 200px;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 80px;
`;

const DeskImage = styled.img`
width: 100%;
`;
const MobileImage = styled.img`
width: 100%;
`;


const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;
const InfoItem = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-bottom: 25px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .box {
      width: 70%;
    }
  }
`;
const InfoItemTitle = styled.div`
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 10px;
  @media only screen and (max-width: 1550px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 1000px) {
    width: 12%;
    font-size: 15px;
  }
`;
const InfoItemDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  @media only screen and (max-width: 1550px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 13px;
  }
  @media only screen and (max-width: 1100px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 15px;
  }
`;
const InfoItemBar = styled.div`
  width: 1px;
  background-color: black;
`;

const Bottom = styled.section`
  margin-bottom: 200px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    margin-bottom: 130px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 34%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0 auto;
		justify-content: center;
  }
  .title {
    font-size: 35px;
    font-weight: bold;
		

    @media only screen and (max-width: 1200px) {
      font-size: 25px;
			width: 85%;
			margin: 0 auto;
    }
  }
  .firstDesc,
  .secondDesc {
    font-size: 18px;
    line-height: 1.3;
    @media only screen and (max-width: 1200px) {
      margin: 0 auto;
      font-size: 14px;
			width: 85%;
    }
  }

  .firstDesc {
    margin: 35px auto 20px auto;
		
    @media only screen and (max-width: 1200px) {
      margin-top: 25px;
			
    }
  }

  .secondDesc {
		line-height: 1.6;
		@media only screen and (max-width: 1200px) {
			font-size: 13px
    }
  }
`;

const DescriptionImageContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
  }
  .image {
    width: 100%;
  }
`;
