import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './App';
import GlobalStyle from './style/global';
import theme from './style/theme';

ReactDOM.render(
	<>
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<App />
				<GlobalStyle></GlobalStyle>
			</ThemeProvider>
		</React.StrictMode>
	</>,
	document.getElementById('root'),
);
