import React from "react";
import ReactPageScroller from "react-page-scroller";
import { Link } from "react-router-dom";
import styled from "styled-components";
import blackAngle from "../assets/Images/header/black-tri-angle.png";
import stoneImg from "../assets/Images/portfolio/aprillxstone/main.jpg";
import kepcoImg from "../assets/Images/portfolio/kepco/2.jpg";
import dogImg from "../assets/Images/portfolio/kistchhouse/main.jpg";
import kocomImg from "../assets/Images/mobile/Kocom.jpg";
import treeImg from "../assets/Images/portfolio/nobletree/1.jpg";
import treeImgM from "../assets/Images/portfolio/nobletree/1.jpg";
import stoneImgM from '../assets/Images/mobile/AprilFullM.jpg'
import useWindowDimensions from "../components/UsewindowDimensions";

const Projects = () => {
  const { width, height } = useWindowDimensions();

  return (
    <>
      {width > 1400 && (
        <PCView width={width} height={height}>
          <ReactPageScroller>
            <Container>
              <Box width={width} height={height} style={{ marginTop: "20px" }}>
                <TopLeft width={width} height={height}>
                  <Link to="projects/kokom">
                    <div className="imgContainer">
                      <div className="title">THE FRAME</div>
                      <img src={kocomImg} alt="kocomImg" />
                      <div className="desc">
                        KOCOM / Smart control wall pad / 2021
                      </div>
                    </div>
                  </Link>
                </TopLeft>
              </Box>

              <Box width={width} height={height}>
                <TopRight width={width} height={height}>
                  <div className="imgContainer">
                    <div className="title">ON-ROAD MONITORING DEVICE</div>
                    <Link to="projects/kepco">
                      <img src={kepcoImg} alt="kocomImg" />
                    </Link>
                  </div>
                  <div className="desc">
                    KEPCO / Electronic equipment monitoring device / 2020
                  </div>
                </TopRight>
              </Box>
            </Container>
            <Container>
              <Box width={width} height={height}>
                <MidLeft>
                  <div className="imgContainer">
                    <div className="title">MINI POT</div>
                    <Link to="projects/minipot">
                      <img src={treeImg} alt="treeImg" />
                    </Link>
                    <div className="desc">
                      GEEGU / Eco-friendly plant pot / 2020
                    </div>
                  </div>
                </MidLeft>
              </Box>
              <Box width={width} height={height}>
                <MidRight>
                  <div className="imgContainer">
                    <div className="title">KISTCH HOUSE</div>
                    <Link to="projects/kistchhouse">
                      <img src={dogImg} alt="dogImg" />
                    </Link>
                    <div className="desc">
                      RODEM FURNITURE / Apartment public pet parking / 2019
                    </div>
                  </div>
                </MidRight>
              </Box>
            </Container>
            <Container>
              <Box width={width} height={height} style={{ marginTop: "40px" }}>
                <BotLeft>
                  <div className="imgContainer">
                    <div className="title">GADGET</div>
                    <Link to="projects/aprillxstone">
                      <img src={stoneImg} alt="stoneImg" />
                    </Link>
                    <div className="desc">
                      April x Stone / Mobile charging & sterilizer device / 2019
                    </div>
                  </div>
                </BotLeft>
              </Box>
              <Box width={width} height={height}>
                <BotRight width={width} height={height}>
                  <section>
                    <p>Back to Front</p>
                    <img
                      className="blackAngle"
                      src={blackAngle}
                      alt="blackAngle"
                    />
                  </section>
                </BotRight>
              </Box>
            </Container>
          </ReactPageScroller>
        </PCView>
      )}
      {width < 1400 && (
        <MobileView>
          <Link to="projects/kokom">
            <MobileProductBox width={width} height={height}>
              <div className="imageContainer">
                <img src={kocomImg} alt="kocomImg" />
              </div>
              <div className="titleContainer">
                <ProductTitle> THE FRAME </ProductTitle>
              </div>
              <div className="descContainer">
                <ProductDesc>KOCOM / Smart Control wall pad / 2021</ProductDesc>
              </div>
            </MobileProductBox>
          </Link>
          <Link to="projects/kepco">
            <MobileProductBox width={width} height={height}>
              <div className="imageContainer">
                <img src={kepcoImg} alt="kocomImg" />
              </div>
              <div className="titleContainer">
                <ProductTitle> ON - ROAD MONITORING DEVICE </ProductTitle>
              </div>
              <div className="descContainer">
                <ProductDesc>
                  KEPCO / Electronic equipment monitoring device / 2020
                </ProductDesc>
              </div>
            </MobileProductBox>
          </Link>
          <Link to="projects/minipot">
            <MobileProductBox width={width} height={height}>
              <div className="imageContainer">
                <img src={treeImgM} alt="nobleImg" />
              </div>
              <div className="titleContainer">
                <ProductTitle> MINI POT </ProductTitle>
              </div>
              <div className="descContainer">
                <ProductDesc>
                  GEEGU / Eco - friendly plant pot / 2020
                </ProductDesc>
              </div>
            </MobileProductBox>
          </Link>
          <Link to="projects/kistchhouse">
            <MobileProductBox width={width} height={height}>
              <div className="imageContainer">
                <img src={dogImg} alt="dogImg" />
              </div>
              <div className="titleContainer">
                <ProductTitle> KISTCH HOUSE </ProductTitle>
              </div>
              <div className="descContainer">
                <ProductDesc>
                  RODEM FURNITURE / Apartment public pet parking / 2019
                </ProductDesc>
              </div>
            </MobileProductBox>
          </Link>
          <Link to="projects/aprillxstone">
            <MobileProductBox width={width} height={height}>
              <div className="imageContainer">
                <img src={stoneImgM} alt="stoneImg" />
              </div>
              <div className="titleContainer">
                <ProductTitle> GADGET </ProductTitle>
              </div>
              <div className="descContainer">
                <ProductDesc>
                  April x Stone / Mobile charging & sterilizer device / 2019
                </ProductDesc>
              </div>
            </MobileProductBox>
          </Link>
        </MobileView>
      )}
    </>
  );
};

export default Projects;

const MobileView = styled.section`
  padding-top: 80px;
`;

const MobileProductBox = styled.div`
  margin-bottom: 60px;
  padding: 0 5%;

  .imageContainer {
    width: ${props => props.width * 0.9}px;
    /* width: 100%; */
    height: ${props => props.width * 0.9}px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .titleContainer {
    margin-top: 15px;
    margin-bottom: 2px;
  }
`;

const ProductTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 17px;
    font-weight: 500;
  }
`;

const ProductDesc = styled.div`
  font-size: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const PCView = styled.section`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;


const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 5% 0 4.5%;
`;
const Box = styled.div`
  margin: 0 auto;
  width: ${(props) => props.width / 2}px;
  height: ${(props) => props.height}px;
  display: flex;

`;

const TopLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  position: relative;
  top: 100px;
  left: 50px;

  .imgContainer {
    height: ${(props) => props.height * 0.58}px;
    width: ${(props) => props.width * 0.4}px;
    max-width: 900px;
    max-height: 700px;
    position: relative;

    .title {
      font-size: 23px;
      font-weight: 500;
      transform: rotate(-90deg);
      position: absolute;
      top: 53px;
      left: -90px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .desc {
      font-size: 17px;
      margin-top: 12px;
      position: absolute;
      bottom: -32px;
      left: 0px;
    }
  }
`;
const TopRight = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 50px;
  right: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: end;

  .imgContainer {
    width: ${(props) => props.width * 0.5}px;
    height: ${(props) => props.width * 0.5}px;
    max-width: 550px;
    max-height: 550px;
    position: relative;

    .title {
      font-size: 23px;
      font-weight: 500;
      transform: rotate(90deg);
      position: absolute;
      top: 154px;
      right: -194px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    font-size: 17px;
    margin-top: 12px;
  }
`;

const MidLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  justify-content: center;

  .imgContainer {
    position: relative;
    /* top: 100px; */
    left: 10%;
    width: 74%;
    height: 70%;
    max-height: 800px;

    .title {
      font-size: 23px;
      font-weight: 500;
      transform: rotate(-90deg);
      position: absolute;
      top: 40px;
      left: -85px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    font-size: 17px;
    margin-top: 12px;
  }
`;

const MidRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .imgContainer {
    position: relative;
    left: 17%;
    width: 70%;
    height: 70%;
    max-height: 800px;

    .title {
      font-size: 23px;
      font-weight: 500;
      transform: rotate(90deg);
      position: absolute;
      top: 68px;
      right: -113px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    font-size: 17px;
    margin-top: 12px;
  }
`;

const BotLeft = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .imgContainer {
    position: relative;
    width: 100%;
    height: 50%;
    margin: 0 auto;

    .title {
      font-size: 23px;
      font-weight: 500;
      transform: rotate(90deg);
      position: absolute;
      top: 32px;
      right: -79px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    font-size: 17px;
    margin-top: 12px;
  }
`;
const BotRight = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  justify-content: center;
  align-items: center;

  section {
    margin-top: 100px;
    width: 18%;
    text-align: end;
    margin-right: -65px;

    p {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    img {
      margin-right: -6px;
      width: 14px;
      transform: rotate(135deg);
      object-fit: contain;
    }
  }
`;
