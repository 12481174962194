import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Modal = ({ state }) => {
	const styleClasses = ['Modal', state === 'entering' ? 'ModalOpen' : state === 'exiting' ? 'ModalClose' : null];
	const itemClasses = ['Item', state === 'entering' ? 'ItemOpen' : state === 'exiting' ? 'ItemClose' : null];
	return (
		<Box>
			<div className={styleClasses.join(' ')}>
				<div className="itemContainer">
					<Link to={'/office'}>
						<div className={itemClasses.join(' ')}>Office</div>
					</Link>
					<Link to={'/contact'}>
						<div className={itemClasses.join(' ')}>Contact</div>
					</Link>
					<Link to={'/projects'}>
						<div className={itemClasses.join(' ')}>Projects</div>
					</Link>
				</div>
			</div>
		</Box>
	);
};

export default Modal;

const Box = styled.section`
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-color: rgb(255, 255, 255, 0.6);
	/* opacity: 0.6; */
	z-index: 10;

	.Modal {
		transition: all 0.3s ease-out;
	}

	.ModalOpen {
		opacity: 1;
		animation: ModalIn 0.3s ease-in;
	}

	.itemContainer {
		max-width: 100%;
		margin-top: 80px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		div {
			opacity: 1;
		}
	}

	.ModalClose {
		opacity: 0;
		animation: ModalOut 0.3s ease-out;
	}

	.Item {
		transition: all 0.3s ease;
		margin-bottom: 20px;

		font-size: 35px;
		font-weight: bold;
		padding-right: 37px;
		opacity: 1;
		z-index: 1000;
	}

	.ItemOpen {
		opacity: 1;
		animation: FadeIn 0.3s ease-in;
	}

	.ItemClose {
		opacity: 0;
		animation: FadeOut 1s ease-out;
	}

	@keyframes ModalIn {
		0% {
			opacity: 0;
		}
		1% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes ModalOut {
		0% {
			opacity: 1;
		}
		1% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@keyframes FadeIn {
		0% {
			opacity: 0;
		}
		1% {
			opacity: 0;
			transform: translateY(-120%);
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes FadeOut {
		0% {
			opacity: 1;
		}
		1% {
			opacity: 1;
		}

		90% {
			transform: translateY(-80%);
		}
		100% {
			opacity: 0;
		}
	}
`;
