import React from "react";
import styled from "styled-components";
import image1 from "../assets/Images/office/Top1.jpg";
import image2 from "../assets/Images/office/Top2.jpg";
import image7 from "../assets/Images/office/Mid1.jpg";
import image4 from "../assets/Images/office/Mid2.jpg";
import image5 from "../assets/Images/office/Mid3.jpg";
import image6 from "../assets/Images/office/Bot1.jpg";
import image3 from "../assets/Images/office/Bot2.jpg";
import triAngle from "../assets/Images/header/black-tri-angle.png";
import Footer from "../components/Footer";

const Office = () => {
  return (
    <Page>
      <Top>
        <div className="title"> WE ARE </div>
        <div className="imageContainer">
          <div className="left">
            <img src={image1} alt="image1" />
          </div>
          <div className="right">
            <div className="subTitleContainer">
              <div className="subTitle">A DYNAMIC CREATIVE STUDIO,</div>
              <div className="subTitle">DESIGNING PRODUCT & BRAND</div>
            </div>
            <img src={image2} alt="image2" />
          </div>
        </div>
        <div className="subTitleContainers">
          <div className="subTitle">A DYNAMIC CREATIVE STUDIO,</div>
          <div className="subTitle">DESIGNING PRODUCT & BRAND</div>
        </div>
        <div className="Description">
          <p style={{ marginBottom: "20px" }}>
            We design products and brand strategies to deliver valuable
            experiences in our daily lives. We look over the product and brand
            from an essential point of view and propose a new user story. From
            this, the positive communion is created between a designer, client,
            and user.
          </p>
          <p className="seconDesc">
            우리는 일상에 가치 있는 경험을 전달하는 제품과 브랜드 전략을
            디자인합니다. 제품과 브랜드를 본질적인 관점에서 바라보고 새로운
            사용자 스토리를 제안하며, 이를 통해 디자이너, 클라이언트 그리고
            사용자 간의 긍정적인 교감을 만들어갑니다.
          </p>
        </div>
        <div className="blackTriAngle">
          <img src={triAngle} alt="triAngle" />
        </div>
      </Top>
      <Mid>
        <div className="title"> WE ALWAYS </div>
        <div className="imageContainer">
          <img className="img1" src={image7} alt="" />
          <div className="imageContainer2">
            <img className="img2" src={image4} alt="" />
            <img className="img3" src={image5} alt="" />
          </div>
        </div>
        <div className="subTitleContainer">
          <div className="subTitle"> CONSIDER TO </div>
          <div className="subTitle"> HOW WE CAN BE DIFFERENT </div>
        </div>
        <div className="description">
          <p className="desc1">
            We do not only focus on provocative and temporary design. Minki Kim
            and Kyumin Hwang, who are the founders from university of Seoul
            where is specialized in profound design process, recognize the
            direction of the client and design idea and user experiences focused
            on, the essence of the project and brand story. And then we create
            unique shape and CMF, emphasized the ideas and user experiences,
            while delivering attractive products and brand strategies with
            internal and external values. Not only valuable experiences but also
            brand images are engraved to the users by the designed products from
            the systematic process. This leads to an increase of brand loyalty
            and makes the brands show their influence through the market.
          </p>
          <p className="desc2">
            DIVE는 자극적인 일회성 디자인에 집중하지 않습니다. 심도 깊은 디자인
            프로세스에 특화된 서울시립대학교 출신의 김민기, 황규민 디렉터를
            중심으로 클라이언트의 방향성을 인지하고, 프로젝트의 본질과 브랜드
            스토리에 집중해 아이디어와 사용자 경험을 차례대로 설계합니다. 이후
            아이디어를 강조하는 독창적인 조형과 CMF를 녹여내, 내면과 외면 모두에
            가치를 담은 매력적인 제품과 브랜드 전략을 사용자에게 전달합니다.
            <br /> 체계적인 프로세스를 거쳐 디자인된 상품은 사용자에게 가치 있는
            경험을 제시할 뿐만 아니라 브랜드의 이미지 또한 효과적으로 각인시켜
            브랜드 충성심을 높여줍니다. 이를 통해 시장에서 강력한 영향력을
            발휘할 수 있도록 합니다.
          </p>
        </div>
        <div className="blackTriAngle">
          <img src={triAngle} alt="triAngle" />
        </div>
      </Mid>
      <Bot>
        <div className="title"> OUR TEAM </div>
        <div className="imageContainer">
          <img className="img1" src={image6} alt="" />
          <img className="img2" src={image3} alt="" />
        </div>
        <div className="subTitleContainer">
          <div className="sutTitleBox">
            <div className="subTitle"> MADE UP WITH </div>
            <div className="subTitle"> VARIOUS CHARACTERISTIC </div>
          </div>
        </div>
        <div className="sutTitleBoxs">
          <div className="subTitle"> MADE UP WITH </div>
          <div className="subTitle"> VARIOUS CHARACTERISTIC </div>
        </div>
        <div className="description">
          <p className="desc1">
            DIVE is teamed up with young, passionate designers.We are not only
            tied up with defined processes but also share and apply personal
            opinions and processes, so that the whole practical idea and design
            are created together.
          </p>
          <p className="desc2">
            DIVE는 젊고, 열정 있는 감각적인 디자이너들로 팀을 이루고 있습니다.
            정해진 프로세스에만 얽매이지 않고 각자의 생각과 프로세스를 자유롭게
            공유하고 적용해, 각 프로젝트에 필요한 아이디어와 디자인을
            만들어갑니다.
          </p>
        </div>
        <div className="blackTriAngle">
          <img src={triAngle} alt="triAngle" />
        </div>
      </Bot>
      <EndSection>
        <div className="container">
          <div className="title"> COLLABOLATE & JOIN US </div>
          <div className="blackTriAngle">
            <img src={triAngle} alt="triAngle" />
          </div>
          <div className="email"> hello@divedesign.co </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </EndSection>
      {/* <div className="lastBlackTriAngle">
        <img src={triAngle} alt="triAngle" />
      </div> */}
    </Page>
  );
};

export default Office;

const Page = styled.section`
  max-width: 1400px;
  margin: 0 auto;

  padding: 0 7%;

  /* .lastBlackTriAngle {
		width: 100%;
		margin-bottom: 20px;
		text-align: center;
		img {
			width: 15px;
			transform: rotate(-135deg);
		}
	} */
`;

const Top = styled.section`
  margin-top: 85px;

  .title {
    font-size: 60px;
    font-weight: bold;
    text-align: center;

    @media only screen and (max-width: 1200px) {
      font-size: 45px;
      margin-bottom: 50px;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 40px;
      margin-bottom: 40px;
    }
  }

  .imageContainer {
    margin-top: 50px;
    display: flex;
    width: 100%;

    .left {
      width: 50%;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .right {
      width: 50%;

      .subTitleContainer {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .subTitle {
          font-size: 35px;
          font-weight: 500;

          @media only screen and (max-width: 1200px) {
            font-size: 27px;
          }

          @media only screen and (max-width: 1000px) {
            font-size: 23px;
          }

          @media only screen and (max-width: 800px) {
            font-size: 12px;
            display: none;
          }
        }
      }
      img {
        width: 120%;
        height: 70%;
        margin-left: -20%;
        @media only screen and (max-width: 800px) {
          margin-top: -30px;
        }
      }
    }
  }

  .subTitleContainers {
    display: none;

    @media only screen and (max-width: 800px) {
      display: block;
      margin-top: 78px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 60px;
      text-align: center;
    }
  }

  .Description {
    width: 40%;
    margin-top: 25%;
    font-size: 21px;
    line-height: 1.3;
    .seconDesc {
      font-size: 19px;
      line-height: 1.6;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 20px;
      .seconDesc {
        font-size: 18px;
      }
    }

    @media only screen and (max-width: 800px) {
      margin: 0 auto;
      width: 86%;
      font-size: 15px;
      .seconDesc {
        font-size: 13px;
      }
    }
  }

  .blackTriAngle {
    margin-top: 13%;
    margin-bottom: 15%;
    width: 100%;
    text-align: center;
    img {
      width: 2%;
      height: 2%;
      @media only screen and (max-width: 800px) {
        width: 15px;
      }
    }
  }
`;

const Mid = styled.section`
  .title {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;

    @media only screen and (max-width: 1200px) {
      font-size: 45px;
      margin-bottom: 50px;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 40px;
      margin-bottom: 60px;
    }
  }

  .imageContainer {
    width: 100%;
    margin-bottom: 200px;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 150px;
    }

    @media only screen and (max-width: 1000px) {
      margin-bottom: 103px;
    }
    display: flex;
    flex-direction: column;
    img {
      width: 40%;
      object-fit: cover;
    }

    .imageContainer2 {
      margin-top: -20%;
      margin-left: 10%;
    }
    .img1 {
      margin: 0 auto;
    }
    .img3 {
      position: relative;
      top: 50px;
      margin-left: -3%;
      width: 45%;
    }
  }

  .subTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
    @media only screen and (max-width: 1200px) {
      margin-bottom: 80px;
    }

    @media only screen and (max-width: 1000px) {
      margin-bottom: 60px;
    }
    .subTitle {
      font-size: 35px;
      font-weight: 500;

      @media only screen and (max-width: 1200px) {
        font-size: 27px;
      }

      @media only screen and (max-width: 1000px) {
        font-size: 23px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .description {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }

    .desc1 {
      width: 42%;
      font-size: 21px;
      line-height: 1.3;
      @media only screen and (max-width: 1000px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 15px;
        width: 86%;
        margin-bottom: 20px;
      }
    }
    .desc2 {
      width: 41%;
      font-size: 19px;
      line-height: 1.6;
      @media only screen and (max-width: 1000px) {
        font-size: 18px;
      }

      @media only screen and (max-width: 800px) {
        font-size: 13px;
        width: 86%;
      }
    }

    @media only screen and (max-width: 1000px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 800px) {
      font-size: 15px;
    }
  }

  .blackTriAngle {
    margin-top: 13%;
    margin-bottom: 15%;
    width: 100%;
    padding: 0;
    height: 2%;
    text-align: center;
    img {
      width: 2%;
      height: 2%;
      @media only screen and (max-width: 800px) {
        width: 15px;
      }
    }
  }
`;

const Bot = styled.section`
  width: 100%;
  height: 100%;
  margin-top: 100px;
  margin-bottom: 120px;

  @media only screen and (max-width: 800px) {
    margin-top: 0px;
  }

  .title {
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 60px;

    @media only screen and (max-width: 1200px) {
      font-size: 45px;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 40px;
    }
  }

  .imageContainer {
    width: 100%;
    position: relative;
    display: flex;
    img {
      width: 53%;
      object-fit: cover;
    }

    .img2 {
      position: absolute;
      top: 18%;
      right: 0%;
      margin-left: -8%;
    }
  }

  .sutTitleBoxs {
    display: none;
    @media only screen and (max-width: 800px) {
      display: block;
      margin-top: 100px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 60px;
      text-align: center;
      display: block;
    }
  }

  .subTitleContainer {
    width: 100%;
    margin-bottom: 15%;
    margin-top: 7%;

    .subTitle {
      font-size: 35px;
      font-weight: 500;

      @media only screen and (max-width: 1200px) {
        font-size: 20px;
      }

      @media only screen and (max-width: 1000px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 10px;
        display: none;
      }
    }
  }

  .description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 1.4;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
      align-items: center;
    }

    .desc1 {
      width: 42%;
      font-size: 21px;
      line-height: 1.3;
      @media only screen and (max-width: 1000px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 800px) {
        font-size: 15px;
        width: 86%;
        margin-bottom: 20px;
      }
    }
    .desc2 {
      width: 41%;
      line-height: 1.6;
      font-size: 19px;
      @media only screen and (max-width: 1000px) {
        font-size: 18px;
      }

      @media only screen and (max-width: 800px) {
        font-size: 13px;
        width: 86%;
      }
    }

    @media only screen and (max-width: 1000px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 800px) {
      font-size: 15px;
    }
  }

  .blackTriAngle {
    margin-top: 13%;
    /* margin-bottom: 15%; */
    width: 100%;
    text-align: center;
    height: 2%;
    img {
      width: 2%;
      height: 2%;
      @media only screen and (max-width: 800px) {
        width: 15px;
      }
    }
  }
`;

const EndSection = styled.section`
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 4.6%;

    @media only screen and (max-width: 1200px) {
      font-size: 25px;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 800px) {
      margin-bottom: 30px;
    }
  }

  .blackTriAngle {
    text-align: center;
    margin-bottom: 4.8%;
    @media only screen and (max-width: 800px) {
      margin-bottom: 30px;
    }
    img {
      width: 30%;
      @media only screen and (max-width: 800px) {
        width: 15px;
      }
    }
  }

  .email {
    font-size: 24px;
    font-weight: 500;

    @media only screen and (max-width: 1200px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 1000px) {
      font-size: 16px;
    }
  }
`;
