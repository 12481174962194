import React from "react";
import styled from "styled-components";
import mainImg from "../../assets/Images/portfolio/kepco/main.jpg";
import img1 from "../../assets/Images/portfolio/kepco/1.jpg";
import img2 from "../../assets/Images/portfolio/kepco/2.jpg";
import img3 from "../../assets/Images/portfolio/kepco/3.jpg";
import img4 from "../../assets/Images/portfolio/kepco/4.jpg";
import img5 from "../../assets/Images/portfolio/kepco/5.jpg";
import img6 from "../../assets/Images/portfolio/kepco/6.jpg";
import img7 from "../../assets/Images/portfolio/kepco/7.jpg";
import img8 from "../../assets/Images/portfolio/kepco/8.jpg";
import Footer from "../../components/Footer";
import useWindowDimensions from "../../components/UsewindowDimensions";
import FullBox from "../../components/templates/FullBox";

const Kepco = () => {
  React.useEffect(() => {
    window.scroll(0,0)
  }, []);
  const { width } = useWindowDimensions();
  return (
    <Container>
      <MainImageBox width={width}>
        <img src={mainImg} alt="asd" />
      </MainImageBox>
      <MainDescBox>
        <div className="descBox">
          <div className="titleContainer">
            <p className="title">
              On-road electronic equipment monitoring device
            </p>
            <p>Category / Smart monitoring device</p>
            <p>Client / Kepco</p>
            <p>Year / 2019</p>
          </div>
          <div className="descContainer">
            <p className="firstDesc">
              This monitoring device is the design for KEPCO which is
              responsible for Korean electricity provision. It inspects electric
              supply equipment by internal sensing camera on the roof of the
              vehicles.
            </p>
            <p>
              The feature of this design is the efficient arrangement of the
              camera and iconic aesthetics, which is to countervail the negative
              image such as the supervisory units. This helps to reduce the
              sense of refusal for other vehicles or pedestrians and deliver
              friendly images. Further, rain falls down easily from this soft
              external surface. The spotty images of details which are to bring
              up the image of headlights or backlights of the vehicles remind
              the advanced equipment, and mingle well with the vehicle. The X
              frame on the top emphasizes the durability of the product within
              the soft line, helps approach the parts where is necessary to be
              fixed, easily and quickly.
            </p>
          </div>
        </div>
      </MainDescBox>
      <FullBox>
        <img src={img1} alt="" />
      </FullBox>
      <FullBox>
        <img src={img2} alt="" />
      </FullBox>
      <FullBox>
        <img src={img3} alt="" />
      </FullBox>
      <FullBox>
        <img src={img4} alt="" />
      </FullBox>
      <FullBox>
        <img src={img5} alt="" />
      </FullBox>
      <FullBox>
        <img src={img6} alt="" />
      </FullBox>
      <FullBox>
        <img src={img7} alt="" />
      </FullBox>
      <FullBox>
        <img src={img8} alt="" />
      </FullBox>


      <div className="footer">
        <Footer />
      </div>
    </Container>
  );
};

export default Kepco;

const Container = styled.section`
  width: 100%;

  .footer {
    margin-top: 200px;
    padding: 0 10%;
    @media only screen and (max-width: 768px) {
      padding: 0 5%;
      margin-top: 100px;
    }
  }
`;
const MainImageBox = styled.div`
  width: ${(props) => props.width};
  height: 100vh;
  @media only screen and (max-width: 1000px) {
    position: relative;
    height: 700px;
  }
  @media only screen and (max-width: 768px) {
    position: relative;
    width: ${(props) => props.width}px;
    height: ${(props) => props.width + 60}px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media only screen and (max-width: 1000px) {
      transform: scale(1.3);
    }
    @media only screen and (max-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(100, 100);
      height: ${(props) => props.width}px;
      height: ${(props) => props.width}px;
      object-fit: cover;
      margin: auto;
    }
  }
`;

const MainDescBox = styled.div`
  max-width: 1300px;
  padding: 0 10%;
  margin: 0 auto;

  @media only screen and (max-width: 1000px) {
    position: relative;
    background-color: #f3f0ec;
    top: -60px;
  }

  .titleContainer {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .descContainer {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .descBox {
    width: 100%;
    display: flex;
    padding: 90px 0;
    font-size: 16px;
    line-height: 1.3;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 22px;
  }

  .firstDesc {
    margin-bottom: 20px;
  }
  div {
    margin: 0 auto;
  }
`;

